import React, { useState, useEffect } from "react"
import * as queryString from "query-string";
import VisibilitySensor from 'react-visibility-sensor';
import "../css/style.scss"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import SideMenu from "../components/side-menu"
import Hero2 from "../components/hero2"
import Section2 from "../components/section-2"
import Section3 from "../components/section-3"
import Section4 from "../components/section-4"
import Section5 from "../components/section-5"
import Section6 from "../components/section-6"
import Footer from "../components/footer"

const FirstClass = ({ location }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [visibleSection, setVisibleSection] = useState('')

    const params = new URLSearchParams(location.search);
    const returnToPage = params.get("return");

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
        }
        setTimeout(() => {
            setVisibleSection('')
        }, 100)
    }, [setIsMobile, setVisibleSection])

    return (
        <Layout>
            <Seo title="First class in-flight pleasure index | Upgraded Points" path="first-class" />
            <Header />
            <main className="first-class">
                <SideMenu visibleSection={visibleSection} />
                <VisibilitySensor
                    onChange={(isVisible) => {
                        setVisibleSection('#hero')
                    }}
                >
                    <Hero2 isMobile={isMobile} page={'first-class'} />
                </VisibilitySensor>
                <VisibilitySensor
                    partialVisibility={"top"}
                    onChange={(isVisible) => {
                        setVisibleSection('#section2')
                    }}
                >
                    <Section2 isMobile={isMobile} page={'first-class'} />
                </VisibilitySensor>
                <Section3 back={returnToPage} isMobile={isMobile} page={'first-class'} setVisibleSection={setVisibleSection} />

                <Section5 />
                <Section6 />
            </main>
            <Footer />
        </Layout >
    );
};

export default FirstClass;
